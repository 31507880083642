import React, { lazy } from 'react';
import tColor from 'tinycolor2';
import Cookies from 'js-cookie';

interface ILazyPreloadComponent
  extends React.LazyExoticComponent<React.ComponentType<any>> {
  preload: () => ReturnType<Parameters<typeof lazy>[0]>;
}
export function ReactLazyPreload(
  importStatement: Parameters<typeof lazy>[0]
): ILazyPreloadComponent {
  const Component = lazy(importStatement) as ILazyPreloadComponent;
  Component.preload = importStatement;
  return Component;
}

export function isLightFontNeeded(bgColor?: string): boolean {
  if (!bgColor) return false;
  return tColor(bgColor).getBrightness() < 200;
}

export function isLightColor(color?: string): boolean {
  if (!color) return false;
  return tColor(color).getBrightness() > 200;
}

/** keep only lesson with id. if lessonId is -1 return '/lessons' */
export function getLessonPathFromUrl(url: string, lessonId?: number | string): string {
  if (!lessonId) {
    const match = url.match(/^.*\/lessons(?:\/[1-9]\d*)?/);
    return match ? match[0] : '';
  }

  const lessonIdString = String(lessonId);
  const match = url.match(/^.*\/lessons/);
  let result = match ? match[0] : '';
  if (!Number.isNaN(parseInt(lessonIdString, 10))) {
    const replacer = lessonId === -1 ? '' : `/${lessonIdString}`;
    result += replacer;
  }
  return result;
}

export function convertHexToRgb(hex: string): { r: number; b: number; g: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

export function htmlStringHasContent(htmlString: string): boolean {
  try {
    const text = new DOMParser()
      .parseFromString(htmlString, 'text/html')
      .querySelector('body')
      ?.innerText.trim();
    return !!text;
  } catch {
    return true;
  }
}

export function getCookie(name: string): string | undefined {
  return Cookies.get(name);
}

export function setCookie(name: string, value: string, daysToLive: number): void {
  Cookies.set(name, value, { expires: daysToLive });
}

export function isIE(): boolean {
  const { userAgent } = navigator;

  return userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1;
}

export function isIOS(maxVersion?: number): boolean {
  const { userAgent } = navigator;

  if (/(iPhone|iPod|iPad)/i.test(userAgent)) {
    if (maxVersion) {
      const iOSVersion = userAgent.match(/OS [\d_]+/i);

      return (
        !!iOSVersion && parseInt(iOSVersion[0].substr(3).split('_')[0], 10) < maxVersion
      );
    }

    return true;
  }

  return false;
}

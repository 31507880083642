import { getApi } from '@adeptlms/lingu-students-react-shared';

import { APP_SERVER_URL } from 'students/constants';
import { IUploadFileResult } from './types';

export async function uploadFile(file: File): Promise<IUploadFileResult> {
  const formData = new FormData();
  formData.append('files[]', file);

  const { data } = await getApi().post<IUploadFileResult>('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-INFLECT-WITH': 'snake'
    },
    baseURL: APP_SERVER_URL || '/'
  });

  return data;
}

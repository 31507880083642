import { sharedReducer } from '@adeptlms/lingu-students-react-shared';
import { combineReducers } from '@reduxjs/toolkit';
import { ReducersMapObject } from 'redux';
import authReducer from './auth';
import modalReducer from './modal';
import routerHistoryReducer from './routerHistory';

interface IState {
  shared: ReturnType<typeof sharedReducer>;
  auth: ReturnType<typeof authReducer>;
  modal: ReturnType<typeof modalReducer>;
  routerHistory: ReturnType<typeof routerHistoryReducer>;
}

const reducerMap: ReducersMapObject<IState> = {
  shared: sharedReducer,
  auth: authReducer,
  modal: modalReducer,
  routerHistory: routerHistoryReducer
};

const rootReducer = combineReducers(reducerMap);

export default rootReducer;

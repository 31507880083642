import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location as HistoryLocation } from 'history';

interface IRouterHistory {
  locations: Array<HistoryLocation>;
}

const initialRouterHistoryState: IRouterHistory = {
  locations: []
};

const routerHistorySlice = createSlice({
  name: 'routerHistory',

  initialState: initialRouterHistoryState,

  reducers: {
    add: (state, action: PayloadAction<IRouterHistory['locations'][0]>) => {
      state.locations.push(action.payload);
    },

    drop: () => {
      return initialRouterHistoryState;
    }
  }
});

const {
  reducer,
  actions: { add, drop }
} = routerHistorySlice;

export const actions = { add, drop };

export default reducer;

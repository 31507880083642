import { useEffect, useMemo } from 'react';
import { IUseTimeout } from '@adeptlms/lingu-students-react-shared';

type tTimeoutId = number;

const useTimeout: IUseTimeout = () => {
  const timeoutsSet = useMemo(() => new Set<tTimeoutId>(), []);

  const createTimeout = (callback: () => void, delay?: number) => {
    const timeout = window.setTimeout(callback, delay);
    timeoutsSet.add(timeout);
    return timeout;
  };

  const dropTimeout = (timeout?: tTimeoutId) => {
    if (timeout) {
      window.clearTimeout(timeout);
      timeoutsSet.delete(timeout);
    }
  };

  useEffect(() => {
    return () => {
      timeoutsSet.forEach((timeout) => {
        clearTimeout(timeout);
      });

      timeoutsSet.clear();
    };
  }, [timeoutsSet]);

  return { createTimeout, dropTimeout };
};

export { useTimeout };

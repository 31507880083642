import { createSelector, Selector } from '@reduxjs/toolkit';
import type { tAppState } from '../rootStore';

const selectModalStore: Selector<tAppState, tAppState['modal']> = (state) => state.modal;

const selectModalOpened: Selector<tAppState, tAppState['modal']['openedModals']> =
  createSelector([selectModalStore], (modalStore) => modalStore.openedModals);

const selectModalOpenedKeys: Selector<tAppState, string[]> = createSelector(
  [selectModalOpened],
  (openedModals) =>
    openedModals.map((modal: tAppState['modal']['openedModals'][number]) => modal.key)
);

const selectOpenedModalByKey = (
  modalKey: string
): Selector<tAppState, tAppState['modal']['openedModals'][0] | undefined> =>
  createSelector([selectModalOpened], (openedModals) =>
    openedModals.find(
      (modal: tAppState['modal']['openedModals'][number]) => modal.key === modalKey
    )
  );

const modalSelectors = {
  selectModalStore,
  selectModalOpened,
  selectModalOpenedKeys,
  selectOpenedModalByKey
};

export default modalSelectors;

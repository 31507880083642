import { I18n, t, l, lookup, translations } from './i18nEngine';
import I18nContext from './I18nContext';
import { getSupportedLocales } from './helpers';
import { Translate, I18nProvider, useLocale } from './components';

const locales = getSupportedLocales();

export default I18n;
export {
  t,
  l,
  lookup,
  locales,
  I18nContext,
  Translate,
  I18nProvider,
  translations,
  useLocale
};

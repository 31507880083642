import React, { ComponentType, useContext } from 'react';
import TranslationEditor, { tPlacementProps } from './TranslationEditor';
import I18nContext from '../I18nContext';
import { tProps } from '../types';
import { lookup } from '../i18nEngine';

interface IHocProps {
  withEditableTranslateNotAllowed?: boolean;
}

export default function withEditableTranslate<P extends tProps>(
  WrappedComponent: ComponentType<P>
): React.FC<P & tPlacementProps> {
  const HocComponent: React.FC<P & tPlacementProps & IHocProps> = ({
    editButtonPlacement,
    editorPlacement,
    withEditableTranslateNotAllowed,
    ...props
  }) => {
    const { str, params, scope } = props;
    const { locale, showTranslationEditor } = useContext(I18nContext);

    if (!showTranslationEditor || withEditableTranslateNotAllowed) {
      return <WrappedComponent {...(props as P)} />;
    }
    return (
      <TranslationEditor
        str={lookup(str, { ...params, scope, locale }) || ''}
        locale={locale}
        translationKey={[scope, str].filter(Boolean).join('.')}
        editButtonPlacement={editButtonPlacement}
        editorPlacement={editorPlacement}
      >
        <WrappedComponent {...(props as P)} />
      </TranslationEditor>
    );
  };
  return HocComponent;
}

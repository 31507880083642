import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { LottieOptions } from 'lottie-react';
import LottieIcon from '../LottieIcon';
import spinnerDotsWhite from './assets/spinnerDotsWhite.json';
import spinnerDotsDark from './assets/spinnerDotsDark.json';
import spinnerDotsDarkBlue from './assets/spinnerDotsDarkBlue.json';

export type tSpinnerVariant = 'dark' | 'darkBlue' | 'light';

export interface ISpinnerProps {
  className?: string;
  variant?: tSpinnerVariant;
}

const spinnerVariants = new Map<tSpinnerVariant, LottieOptions['animationData']>([
  ['light', spinnerDotsWhite],
  ['dark', spinnerDotsDark],
  ['darkBlue', spinnerDotsDarkBlue]
]);

const Spinner: FC<ISpinnerProps> = ({ className, variant }) => {
  const JSONData = useMemo(() => {
    return spinnerVariants.get(variant || 'light');
  }, [variant]);

  return (
    <Wrapper className={cn(className)}>
      <LottieIcon
        animationJSONData={JSONData || spinnerDotsWhite}
        loop
        autoPlay
        speed={2}
        height="36px"
        width="72px"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Spinner;

/* eslint-disable consistent-return */
import { authApi, profileActions } from '@adeptlms/lingu-students-react-shared';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAuth } from 'students/models';
import { postAuthCompleteMessage } from 'students/stores/_utils/helpers';
import type { tAppDispatch, tAppState } from '../rootStore';
import { logOutStorageClear } from './utils/logOutStorageClear';

const initialAuthState: IAuth = {
  modal: {
    show: false,
    isSignUp: false
  },
  tempUser: null,
  invitation: null,
  invitationExpired: false
};

const checkAuth = createAsyncThunk<void, void, { dispatch: tAppDispatch }>(
  'auth/checkAuth_request',
  async (_, { dispatch }) => {
    await dispatch(profileActions.getProfile());
  }
);

const mailSignIn = createAsyncThunk<
  unknown,
  Parameters<typeof authApi.mailSignIn>[0],
  { dispatch: tAppDispatch; rejectValue: string }
>('auth/mailSignIn_request', async (student, { dispatch, rejectWithValue }) => {
  try {
    const profile = await authApi.mailSignIn(student);

    if (profile?.redirectURL) {
      window.location.href = profile.redirectURL;

      return;
    }

    const setProfilePromise = dispatch(profileActions.setProfile(profile));
    dispatch(actions.close());
    postAuthCompleteMessage();
    return setProfilePromise;
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return rejectWithValue(error.response.data.error);
    } else if (error?.response?.data?.errors) {
      const errorKey = Object.keys(error.response.data.errors)[0];
      const errorMessage = `${errorKey} ${error.response.data.errors[errorKey]}`;

      return rejectWithValue(errorMessage);
    }

    return rejectWithValue('Error happened while trying to sign you in');
  }
});

const mailSignUp = createAsyncThunk<
  unknown,
  Parameters<typeof authApi.mailSignUp>[0],
  {
    rejectValue: string;
  }
>('auth/mailSignUp_request', async (student, { rejectWithValue }) => {
  try {
    await authApi.mailSignUp(student);
    return undefined;
  } catch (error: any) {
    if (error?.response?.data?.errors) {
      const errorKey = Object.keys(error.response.data.errors)[0];
      const errorMessage = `${errorKey} ${error.response.data.errors[errorKey]}`;

      return rejectWithValue(errorMessage);
    }

    return rejectWithValue('Error happened while trying to sign you up');
  }
});

const getOTP = createAsyncThunk<
  unknown,
  Parameters<typeof authApi.getOTP>[0],
  {
    dispatch: tAppDispatch;
    rejectValue: string;
  }
>('auth/getOTP_request', async (student, { dispatch, rejectWithValue }) => {
  dispatch(actions.setTempUser(student));

  try {
    await authApi.getOTP(student);

    return student;
  } catch (error: any) {
    return rejectWithValue(
      error?.response?.statusText || 'Error happened while trying to generate OTP'
    );
  }
});

const logOut = createAsyncThunk<unknown, void, { dispatch: tAppDispatch }>(
  'auth/logOut_request',
  async (_, { dispatch }) => {
    await authApi.signOut();

    dispatch(logOutStorageClear());

    window.location.href = '/';
  }
);

const checkInvitation = createAsyncThunk<
  unknown,
  string,
  { dispatch: tAppDispatch; rejectValue: string }
>('auth/checkInvitation_request', async (inviteId, { dispatch, rejectWithValue }) => {
  try {
    await authApi.checkInvitation(inviteId).then(
      (response) => {
        if (response) {
          dispatch(actions.setInvitation(response));
        }
      },
      (err) => {
        if (err.response.status === 403) {
          dispatch(actions.setInvitation(null));
          dispatch(actions.setInvitationExpired(true));
        } else {
          dispatch(actions.setInvitation(null));
          dispatch(actions.setInvitationExpired(false));
        }

        return rejectWithValue(err?.message || '');
      }
    );
  } catch (error: any) {
    dispatch(actions.setInvitationExpired(false));
    dispatch(actions.setInvitation(null));

    return rejectWithValue(
      error?.response?.statusText || 'Error happened while trying to check invitation'
    );
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setTempUser: (state, action: PayloadAction<IAuth['tempUser']>) => {
      state.tempUser = action.payload;
    },

    open: (state) => {
      state.modal.show = true;
      state.modal.isSignUp = initialAuthState.modal.isSignUp;
      state.tempUser = null;
    },

    close: (state) => {
      state.modal.show = initialAuthState.modal.show;
      state.modal.isSignUp = initialAuthState.modal.isSignUp;
      state.tempUser = null;
    },

    toggleVariant: (state) => {
      state.modal.isSignUp = !state.modal.isSignUp;
    },

    setInvitation: (state, action: PayloadAction<IAuth['invitation']>) => {
      state.invitation = action.payload;
    },

    setInvitationExpired: (state, action: PayloadAction<IAuth['invitationExpired']>) => {
      state.invitationExpired = action.payload;
    }
  }
});

const { reducer, actions } = authSlice;

const needAuthCheck = createAsyncThunk<
  void,
  (_: any) => Promise<any>,
  { dispatch: tAppDispatch; state: tAppState }
>('auth/check', async (thunkToCall, { getState, dispatch }) => {
  if (!getState().shared.user.profile) {
    return dispatch(actions.open());
  } else {
    return dispatch(thunkToCall);
  }
});

const exportActions = {
  checkAuth,
  mailSignIn,
  mailSignUp,
  getOTP,
  needAuthCheck,
  logOut,
  logOutStorageClear,
  checkInvitation,
  ...actions
};

export { exportActions as actions };

export default reducer;

import React, { SyntheticEvent, useCallback } from 'react';
import { CloseIcon, TranslateIcon } from 'students/views/shared/components/icons';
import styled from 'styled-components';
import Translate from './Translate';

interface IProps {
  showEditor: boolean;
  toggle: () => void;
}

const TranslationEditToggle: React.FC<IProps> = ({ showEditor, toggle }) => {
  const handleClick = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      toggle();
    },
    [toggle]
  );

  return (
    <SToggleWrap className="translation-edit-toggle">
      {showEditor ? (
        <SEnabledWrapper>
          <SCloseButton onClick={handleClick}>
            <CloseIcon color="white" width="12px" height="12px" />
          </SCloseButton>
          <Translate str="frontend.locale.exit_fix_mode" editButtonPlacement="top" />
        </SEnabledWrapper>
      ) : (
        <SEnableButton onClick={handleClick}>
          <TranslateIcon width="43px" height="37px" />
        </SEnableButton>
      )}
    </SToggleWrap>
  );
};

const SToggleWrap = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
`;

const SEnableButton = styled.button`
  border: none;
  background: var(--color-white);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 8px 2px 4px 6px;
`;

const SEnabledWrapper = styled.div`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 17px 16px;
  font-family: var(--lingu-text-font-family);
  color: var(--color-white);
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: bold;
`;

const SCloseButton = styled.button`
  background-color: transparent;
  border: none;
  margin-inline-end: 11px;
`;

export default TranslationEditToggle;

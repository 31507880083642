import angleArrowRightSvg from 'students/views/shared/assets/icons/angle_arrow_right.svg';
import backArrow from 'students/views/shared/assets/icons/back_arrow_icon.svg';
import plusSvg from 'students/views/shared/assets/icons/blue-plus.svg';
import closeLightSvg from 'students/views/shared/assets/icons/close_icon.svg';
import closeSvg from 'students/views/shared/assets/icons/cross_btn.svg';
import doubleAngleArrowLeftSvg from 'students/views/shared/assets/icons/double_angle_arrow_left.svg';
import forwardArrow from 'students/views/shared/assets/icons/forward_arrow_icon.svg';
import pencilSvg from 'students/views/shared/assets/icons/pencil.svg';
import pickedSvg from 'students/views/shared/assets/icons/picked.svg';
import translateSvg from 'students/views/shared/assets/icons/translate_update_icon.svg';
import vocabularySvg from 'students/views/shared/assets/icons/vocabulary_icon.svg';
import warningSvg from 'students/views/shared/assets/icons/warning_icon.svg';

import { iconCreator } from './iconCreator';

export const PickedIcon = iconCreator(pickedSvg);
export const PlusIcon = iconCreator(plusSvg);
export const BackArrowIcon = iconCreator(backArrow);
export const ForwardArrowIcon = iconCreator(forwardArrow);
export const CloseIcon = iconCreator(closeSvg);
export const CloseLightIcon = iconCreator(closeLightSvg);
export const PencilIcon = iconCreator(pencilSvg);
export const AngleArrowRightIcon = iconCreator(angleArrowRightSvg);
export const DoubleAngleArrowLeftIcon = iconCreator(doubleAngleArrowLeftSvg);
export const TranslateIcon = iconCreator(translateSvg);
export const VocabularyIcon = iconCreator(vocabularySvg);
export const WarningIcon = iconCreator(warningSvg);

import { useCallback, useEffect, useState } from 'react';
import { DEFAULT_SHOW_TRANSLATION_EDITOR } from 'students/views/shared/providers/Preferences/Config';

export const useToggleTranslationEditor = (
  editorModeEnabled: boolean
): [boolean, () => void] => {
  const [showTranslationEditor, setShowTranslationEditor] = useState(
    DEFAULT_SHOW_TRANSLATION_EDITOR
  );

  useEffect(() => {
    if (!editorModeEnabled) {
      setShowTranslationEditor(false);
    }
  }, [editorModeEnabled]);

  const toggleShowTranslationEditor = useCallback(() => {
    setShowTranslationEditor((prev) => !prev);
  }, []);

  return [showTranslationEditor, toggleShowTranslationEditor];
};

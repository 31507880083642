import { FC, FormEvent, KeyboardEvent, useCallback } from 'react';
import EditorInput from './EditorInput';
import { tResponseStatus } from './useTranslationEditor';

interface IProps {
  newTranslation: string;
  onNewTranslationChange: (event: FormEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  str: string;
  isPending: boolean;
  responseStatus: tResponseStatus;
}

const ENTER_CHAR_CODE = 13;

const TranslationPopover: FC<IProps> = ({
  newTranslation,
  onNewTranslationChange,
  onSubmit,
  str,
  isPending,
  responseStatus
}) => {
  const onKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      // TODO charCode is deprecated
      if (event.keyCode === ENTER_CHAR_CODE) {
        onSubmit();
      }
    },
    [onSubmit]
  );

  return (
    <div className="ln-bg-white ln-border ln-border-solid ln-border-blue-200 ln-overflow-hidden ln-rounded-[100px] ln-shadow-card">
      <EditorInput
        value={newTranslation}
        onChange={onNewTranslationChange}
        onKeyPress={onKeyPress}
        onSubmit={onSubmit}
        requestStatus={responseStatus}
        disableSubmit={
          str === newTranslation.trim() || isPending || responseStatus === 'failed'
        }
        isPending={isPending}
      />
    </div>
  );
};

export default TranslationPopover;

import React, { ComponentProps } from 'react';
import UrlIcon from '../UrlIcon';

export function iconCreator<P extends ComponentProps<typeof UrlIcon>>(
  url: string
): React.FC<Omit<P, 'url'>> {
  const ComponentWithUrl: React.FC<Omit<P, 'url'>> = (props) => (
    <UrlIcon {...props} url={url} />
  );
  return ComponentWithUrl;
}

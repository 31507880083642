import cn from 'classnames';
import { ChangeEvent, forwardRef, KeyboardEvent, useMemo } from 'react';
import { CloseIcon, PickedIcon } from 'students/views/shared/components/icons';
import { Spinner } from 'students/views/shared/components/Spinner';
import styled from 'styled-components';

interface IProps {
  requestStatus: 'failed' | 'success' | 'pristine';
  value: string;
  isPending: boolean;
  disableSubmit?: boolean;
  className?: string;
  onBlur?: () => void;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}
const EditorInput = forwardRef<HTMLInputElement | null, IProps>(
  (
    {
      requestStatus,
      value,
      isPending,
      disableSubmit,
      className,
      onBlur,
      onKeyPress,
      onChange,
      onSubmit
    },
    forwardedRef
  ) => {
    const icon = useMemo(
      () =>
        requestStatus === 'failed' ? (
          <CloseIcon color="white" width="12px" height="12px" />
        ) : (
          <PickedIcon color="white" width="17px" height="12px" />
        ),
      [requestStatus]
    );

    return (
      <SEditorInputWrapper>
        <SInput
          className={cn(className)}
          type="text"
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          ref={forwardedRef}
        />
        <SSubmitButton
          onClick={onSubmit}
          className={cn({
            failed: requestStatus === 'failed',
            success: requestStatus === 'success'
          })}
          disabled={disableSubmit}
        >
          {isPending ? <SSpinner /> : icon}
        </SSubmitButton>
      </SEditorInputWrapper>
    );
  }
);

export default EditorInput;

const SEditorInputWrapper = styled.div`
  display: flex;
`;

const SSubmitButton = styled.button`
  border: none;
  background: #00a5d7;
  width: 36px;
  height: 36px;
  color: #ffffff;
  border-radius: 50%;
  margin-inline-start: 0.5rem;
  flex-shrink: 0;

  &.failed {
    background: red;
  }

  &.success {
    background: green;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const SInput = styled.input`
  border: none;
  font-size: 1rem;
  color: #2d2d3a;
  line-height: 1.375rem;
  padding-inline-start: 0.75rem;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const SSpinner = styled(Spinner)`
  & > div {
    height: auto !important;
  }
`;

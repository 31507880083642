import { useEffect, useRef, useState } from 'react';

const getQuery = (breakPointNumber: number, isMaxWidth: boolean) => {
  const query = isMaxWidth
    ? `(max-width: ${breakPointNumber - 1}px)`
    : `(min-width: ${breakPointNumber}px)`;

  return window.matchMedia(query);
};

export default function useBreakpointNumber(
  breakPointNumber?: number,
  isMaxWidth = false
): boolean {
  const mqList = useRef<MediaQueryList | null>(null);
  const [isMatched, setIsMatched] = useState(
    getQuery(breakPointNumber ?? 600, isMaxWidth).matches
  );

  useEffect(() => {
    mqList.current = getQuery(breakPointNumber ?? 600, isMaxWidth);
    setIsMatched(mqList.current.matches);

    function resizeHandler(e: MediaQueryListEvent) {
      setIsMatched(e.matches);
    }

    typeof mqList.current.addEventListener === 'function'
      ? mqList.current.addEventListener('change', resizeHandler)
      : mqList.current.addListener(resizeHandler);

    return () => {
      if (mqList.current) {
        typeof mqList.current.removeEventListener === 'function'
          ? mqList.current.removeEventListener('change', resizeHandler)
          : mqList.current.removeListener(resizeHandler);
      }
    };
  }, [breakPointNumber, isMaxWidth]);

  return isMatched;
}

import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from 'students/constants/envs';

const replay = Sentry.replayIntegration({
  maskAllText: true,
  blockAllMedia: true
});

const feedbackIntegration = Sentry.feedbackIntegration({
  colorScheme: 'system',
  autoInject: false
});

// Filtering errors from browser extensions and external plugins
// https://docs.sentry.io/platforms/javascript/configuration/filtering/
const errorFilteringParams = {
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage'
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /chrome-extension:\/\//i,
    /safari-extension/i,
    /safari-web-extension/i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /braze\.min\.js/i, // Braze
    /@growthbook\/growthbook\/dist/i, // GrowthBook
    /^src\/embeds\/media\/players\/vulcanV2Player/i // Wistia media player
  ]
};

const sentryBaseConfig = {
  dsn: SENTRY_DSN,
  integrations: [replay, feedbackIntegration],
  maxBreadcrumbs: 10,
  environment: import.meta.env.MODE,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.01,
  ...errorFilteringParams
};

export default sentryBaseConfig;

import { IApp, IConstants } from '@adeptlms/lingu-students-react-shared';
import { API_URI, ORDNETT_SEARCH_ENDPOINT } from '../constants';
import { useTimeout } from '../utils/timeout';

class App implements IApp {
  get constants(): IConstants {
    return { API_URI, ORDNETT_SEARCH_ENDPOINT };
  }

  get utils(): IApp['utils'] {
    return {
      useTimeout
    };
  }
}

export default App;

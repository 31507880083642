import _afghanistan from 'i18n/countryFlags/Afghanistan.svg';
import _albania from 'i18n/countryFlags/Albania.svg';
import _armenia from 'i18n/countryFlags/Armenia.svg';
import _azerbaijan from 'i18n/countryFlags/Azerbaijan.svg';
import _bangladesh from 'i18n/countryFlags/Bangladesh.svg';
import _belarus from 'i18n/countryFlags/Belarus.svg';
import _benin from 'i18n/countryFlags/Benin.svg';
import _bosnia from 'i18n/countryFlags/Bosnia and Herzegovina.svg';
import _brazil from 'i18n/countryFlags/Brazil.svg';
import _bulgaria from 'i18n/countryFlags/Bulgaria.svg';
import _cambodia from 'i18n/countryFlags/Cambodia.svg';
import _china from 'i18n/countryFlags/China.svg';
import _co from 'i18n/countryFlags/co.svg';
import _croatia from 'i18n/countryFlags/Croatia.svg';
import _cy from 'i18n/countryFlags/cy.svg';
import _czech from 'i18n/countryFlags/Czech Republic.svg';
import _denmark from 'i18n/countryFlags/Denmark.svg';
import _egypt from 'i18n/countryFlags/Egypt.svg';
import _eo from 'i18n/countryFlags/eo.svg';
import _estonia from 'i18n/countryFlags/Estonia.svg';
import _ethiopia from 'i18n/countryFlags/Ethiopia.svg';
import _finland from 'i18n/countryFlags/Finland.svg';
import _france from 'i18n/countryFlags/France.svg';
import _georgia from 'i18n/countryFlags/Georgia.svg';
import _germany from 'i18n/countryFlags/Germany.svg';
import _greece from 'i18n/countryFlags/Greece.svg';
import _haiti from 'i18n/countryFlags/Haiti.svg';
import _hawaii from 'i18n/countryFlags/Hawaii.svg';
import _hmong from 'i18n/countryFlags/Hmong.svg';
import _hungary from 'i18n/countryFlags/Hungary.svg';
import _iceland from 'i18n/countryFlags/Iceland.svg';
import _indiaHI from 'i18n/countryFlags/India - hi.svg';
import _indiaKN from 'i18n/countryFlags/India - kn.svg';
import _indiaML from 'i18n/countryFlags/India - ml.svg';
import _indiaOR from 'i18n/countryFlags/India - or.svg';
import _indiaTA from 'i18n/countryFlags/India - ta.svg';
import _indiaTE from 'i18n/countryFlags/India - te.svg';
import _india from 'i18n/countryFlags/India.svg';
import _indonesiaJV from 'i18n/countryFlags/Indonesia - jv.svg';
import _indonesia from 'i18n/countryFlags/Indonesia.svg';
import _iran from 'i18n/countryFlags/Iran.svg';
import _iraq from 'i18n/countryFlags/Iraq.svg';
import _ireland from 'i18n/countryFlags/Ireland.svg';
import _israelYI from 'i18n/countryFlags/Israel - yi.svg';
import _israel from 'i18n/countryFlags/Israel.svg';
import _italy from 'i18n/countryFlags/Italy.svg';
import _japan from 'i18n/countryFlags/Japan.svg';
import _kazakhstan from 'i18n/countryFlags/Kazakhstan.svg';
import _kenya from 'i18n/countryFlags/Kenya.svg'; // sw
import _southKorea from 'i18n/countryFlags/Korea - South.svg';
import _kyrgyzstan from 'i18n/countryFlags/Kyrgyzstan.svg';
import _laos from 'i18n/countryFlags/Laos.svg';
import _latvia from 'i18n/countryFlags/Latvia.svg';
import _lesothoSesotho from 'i18n/countryFlags/Lesotho - Sesotho.svg';
import _lesotho from 'i18n/countryFlags/Lesotho.svg';
import _lithuania from 'i18n/countryFlags/Lithuania.svg';
import _luxembourg from 'i18n/countryFlags/Luxembourg.svg';
import _macedonia from 'i18n/countryFlags/Macedonia.svg';
import _madagascar from 'i18n/countryFlags/Madagascar.svg';
import _malawi from 'i18n/countryFlags/Malawi.svg';
import _malaysia from 'i18n/countryFlags/Malaysia.svg';
import _malta from 'i18n/countryFlags/Malta.svg';
import _mongolia from 'i18n/countryFlags/Mongolia.svg';
import _myanmar from 'i18n/countryFlags/Myanmar.svg';
import _nepal from 'i18n/countryFlags/Nepal.svg';
import _netherlandsFrysk from 'i18n/countryFlags/Netherlands - Frysk.svg';
import _netherlands from 'i18n/countryFlags/Netherlands.svg';
import _newZealand from 'i18n/countryFlags/New Zealand.svg';
import _niger from 'i18n/countryFlags/Niger.svg';
import _nigeria from 'i18n/countryFlags/Nigeria.svg';
import _norway from 'i18n/countryFlags/Norway.svg';
import _pakistanSD from 'i18n/countryFlags/Pakistan - sd.svg';
import _pakistan from 'i18n/countryFlags/Pakistan.svg';
import _philippinesSugbuanon from 'i18n/countryFlags/Philippines - Sugbuanon.svg';
import _philippinesWikangTagalog from 'i18n/countryFlags/Philippines - Wikang Tagalog.svg';
import _poland from 'i18n/countryFlags/Poland.svg';
import _romania from 'i18n/countryFlags/Romania.svg';
import _russiaTT from 'i18n/countryFlags/Russia - tt.svg';
import _russia from 'i18n/countryFlags/Russia.svg';
import _rwanda from 'i18n/countryFlags/Rwanda.svg';
import _samoa from 'i18n/countryFlags/Samoa.svg';
import _serbia from 'i18n/countryFlags/Serbia.svg'; // sr
import _slovakia from 'i18n/countryFlags/Slovakia.svg';
import _slovenia from 'i18n/countryFlags/Slovenia.svg';
import _somalia from 'i18n/countryFlags/Somalia.svg'; // so
import _southAfricaIsiXhosa from 'i18n/countryFlags/South Africa - isiXhosa.svg';
import _southAfricaIsiZulu from 'i18n/countryFlags/South Africa - isiZulu.svg';
import _southAfrica from 'i18n/countryFlags/South Africa.svg';
import _spainCatala from 'i18n/countryFlags/Spain - Català.svg';
import _spainEuskara from 'i18n/countryFlags/Spain - Euskara.svg';
import _spainGalego from 'i18n/countryFlags/Spain - Galego.svg';
import _spain from 'i18n/countryFlags/Spain.svg';
import _sriLanka from 'i18n/countryFlags/Sri Lanka.svg';
import _sweden from 'i18n/countryFlags/Sweden.svg'; // sv
import _tajikistan from 'i18n/countryFlags/Tajikistan.svg';
import _thailand from 'i18n/countryFlags/Thailand.svg';
import _tigrinya from 'i18n/countryFlags/Eritrea.svg';
import _turkey from 'i18n/countryFlags/Turkey.svg'; // tr
import _turkmenistan from 'i18n/countryFlags/Turkmenistan.svg';
import _ukraine from 'i18n/countryFlags/Ukraine.svg'; // uk
import _unitedKingdom from 'i18n/countryFlags/United Kingdom.svg';
import _unitedStates from 'i18n/countryFlags/United States of America.svg';
import _uzbekistan from 'i18n/countryFlags/Uzbekistan.svg';
import _va from 'i18n/countryFlags/va.svg';
import _vietnam from 'i18n/countryFlags/Vietnam.svg';
import _zimbabwe from 'i18n/countryFlags/Zimbabwe.svg';

// there is a list of supported locales with it's flags and native name
// locales supported by the backend: config/application.rb -> config.i18n.available_locales
const localeFlags = new Map<string, { flag: string; nativeName: string }>([
  ['af', { flag: _southAfrica, nativeName: 'Afrikaans' }],
  ['am', { flag: _ethiopia, nativeName: 'አማርኛ' }],
  ['ar', { flag: _egypt, nativeName: 'العربية' }],
  ['az', { flag: _azerbaijan, nativeName: 'Azərbaycan dili' }],
  ['be', { flag: _belarus, nativeName: 'Беларуская мова' }],
  ['bg', { flag: _bulgaria, nativeName: 'Български език' }],
  ['bn', { flag: _bangladesh, nativeName: 'বাংলা' }],
  ['bs', { flag: _bosnia, nativeName: 'Bosanski jezik' }],
  ['ca', { flag: _spainCatala, nativeName: 'Català' }],
  ['ceb', { flag: _philippinesSugbuanon, nativeName: 'Sugbuanon' }],
  ['co', { flag: _co, nativeName: 'Corsu' }],
  ['cs', { flag: _czech, nativeName: 'český' }],
  ['cy', { flag: _cy, nativeName: 'Cymraeg' }],
  ['da', { flag: _denmark, nativeName: 'Dansk' }],
  ['de', { flag: _germany, nativeName: 'Deutsch' }],
  ['el', { flag: _greece, nativeName: 'ελληνικά' }],
  ['en', { flag: _unitedStates, nativeName: 'English' }],
  ['eo', { flag: _eo, nativeName: 'Esperanto' }],
  ['es', { flag: _spain, nativeName: 'Español' }],
  ['et', { flag: _estonia, nativeName: 'Eesti' }],
  ['eu', { flag: _spainEuskara, nativeName: 'Euskara' }],
  ['fa', { flag: _iran, nativeName: 'فارسی' }],
  ['fi', { flag: _finland, nativeName: 'Suomi' }],
  ['fr', { flag: _france, nativeName: 'Français' }],
  ['fy', { flag: _netherlandsFrysk, nativeName: 'Frysk' }],
  ['ga', { flag: _ireland, nativeName: 'Gaeilge' }],
  ['gd', { flag: _unitedKingdom, nativeName: 'Gàidhlig' }],
  ['gl', { flag: _spainGalego, nativeName: 'Galego' }],
  ['gu', { flag: _india, nativeName: 'ગુજરાતી' }],
  ['ha', { flag: _niger, nativeName: 'هَوُسَ' }],
  ['haw', { flag: _hawaii, nativeName: 'Hawaiian' }],
  ['he', { flag: _israel, nativeName: 'עברית' }],
  ['hi', { flag: _indiaHI, nativeName: 'हिन्दी' }],
  ['hmn', { flag: _hmong, nativeName: 'Hmong' }],
  ['hr', { flag: _croatia, nativeName: 'Hrvatski jezik' }],
  ['ht', { flag: _haiti, nativeName: 'Kreyòl ayisyen' }],
  ['hu', { flag: _hungary, nativeName: 'Magyar' }],
  ['hy', { flag: _armenia, nativeName: 'Հայերեն' }],
  ['id', { flag: _indonesia, nativeName: 'Bahasa Indonesia' }],
  ['ig', { flag: _nigeria, nativeName: 'Asụsụ Igbo' }],
  ['is', { flag: _iceland, nativeName: 'Íslenska' }],
  ['it', { flag: _italy, nativeName: 'Italiano' }],
  ['ja', { flag: _japan, nativeName: '日本語 (にほんご)' }],
  ['jv', { flag: _indonesiaJV, nativeName: 'ꦧꦱꦗꦮ' }],
  ['ka', { flag: _georgia, nativeName: 'ქართული' }],
  ['kk', { flag: _kazakhstan, nativeName: 'қазақ тілі' }],
  ['km', { flag: _cambodia, nativeName: 'ខ្មែរ' }],
  ['kn', { flag: _indiaKN, nativeName: 'ಕನ್ನಡ' }],
  ['ko', { flag: _southKorea, nativeName: '한국어' }],
  ['ku', { flag: _iraq, nativeName: 'Kurdî' }],
  ['ky', { flag: _kyrgyzstan, nativeName: 'Кыргызча' }],
  ['la', { flag: _va, nativeName: 'Latine' }],
  ['lb', { flag: _luxembourg, nativeName: 'Lëtzebuergesch' }],
  ['lo', { flag: _laos, nativeName: 'ພາສາລາວ' }],
  ['lt', { flag: _lithuania, nativeName: 'lietuvių kalba' }],
  ['lv', { flag: _latvia, nativeName: 'latviešu valoda' }],
  ['mg', { flag: _madagascar, nativeName: 'fiteny malagasy' }],
  ['mi', { flag: _newZealand, nativeName: 'te reo Māori' }],
  ['mk', { flag: _macedonia, nativeName: 'македонски јазик' }],
  ['ml', { flag: _indiaML, nativeName: 'മലയാളം' }],
  ['mn', { flag: _mongolia, nativeName: 'Монгол хэл' }],
  ['mr', { flag: _india, nativeName: 'मराठी' }],
  ['ms', { flag: _malaysia, nativeName: 'بهاس ملايو‎' }],
  ['mt', { flag: _malta, nativeName: 'Malti' }],
  ['my', { flag: _myanmar, nativeName: 'ဗမာစာ' }],
  ['nb', { flag: _norway, nativeName: 'Norsk (bokmål)' }],
  ['nn', { flag: _norway, nativeName: 'Norsk (nynorsk)' }],
  ['ne', { flag: _nepal, nativeName: 'नेपाली' }],
  ['nl', { flag: _netherlands, nativeName: 'Nederlands' }],
  ['ny', { flag: _malawi, nativeName: 'Diné bizaad' }],
  ['or', { flag: _indiaOR, nativeName: 'ଓଡ଼ିଆ' }],
  ['pa', { flag: _india, nativeName: 'ਪੰਜਾਬੀ' }],
  ['pl', { flag: _poland, nativeName: 'Poland' }],
  ['ps', { flag: _afghanistan, nativeName: 'پښتو' }],
  ['pt', { flag: _brazil, nativeName: 'Português' }],
  ['ro', { flag: _romania, nativeName: 'Română' }],
  ['ru', { flag: _russia, nativeName: 'Русский' }],
  ['rw', { flag: _rwanda, nativeName: 'Ikinyarwanda' }],
  ['sd', { flag: _pakistanSD, nativeName: 'सिन्धी' }],
  ['si', { flag: _sriLanka, nativeName: 'සිංහල' }],
  ['sk', { flag: _slovakia, nativeName: 'Slovenčina' }],
  ['sl', { flag: _slovenia, nativeName: 'Slovenski jezik' }],
  ['sm', { flag: _samoa, nativeName: `gagana fa'a Samoa` }],
  ['sn', { flag: _zimbabwe, nativeName: 'chiShona' }],
  ['so', { flag: _somalia, nativeName: 'Soomaaliga' }],
  ['sq', { flag: _albania, nativeName: 'Shqip' }],
  ['sr', { flag: _serbia, nativeName: 'српски језик' }],
  ['st', { flag: _lesothoSesotho, nativeName: 'Sesotho' }],
  ['su', { flag: _lesotho, nativeName: 'Basa Sunda' }],
  ['sv', { flag: _sweden, nativeName: 'Svenska' }],
  ['sw', { flag: _kenya, nativeName: 'Kiswahili' }],
  ['ta', { flag: _indiaTA, nativeName: 'தமிழ்' }],
  ['te', { flag: _indiaTE, nativeName: 'తెలుగు' }],
  ['tg', { flag: _tajikistan, nativeName: 'тоҷикӣ' }],
  ['th', { flag: _thailand, nativeName: 'ไทย' }],
  ['ti', { flag: _tigrinya, nativeName: 'ትግርኛ' }],
  ['tk', { flag: _turkmenistan, nativeName: 'Türkmen' }],
  ['tl', { flag: _philippinesWikangTagalog, nativeName: 'Wikang Tagalog' }],
  ['tr', { flag: _turkey, nativeName: 'Türkçe' }],
  ['tt', { flag: _russiaTT, nativeName: 'татар теле,' }],
  ['ug', { flag: _china, nativeName: 'ئۇيغۇرچە' }],
  ['uk', { flag: _ukraine, nativeName: 'Українська' }],
  ['ur', { flag: _pakistan, nativeName: 'اردو' }],
  ['uz', { flag: _uzbekistan, nativeName: 'Oʻzbek' }],
  ['vi', { flag: _vietnam, nativeName: 'Tiếng Việt' }],
  ['xh', { flag: _southAfricaIsiXhosa, nativeName: 'isiXhosa' }],
  ['yi', { flag: _israelYI, nativeName: 'ייִדיש' }],
  ['yo', { flag: _benin, nativeName: 'Yorùbá' }],
  ['zu', { flag: _southAfricaIsiZulu, nativeName: 'isiZulu' }]
]);

localeFlags.forEach((value) => {
  value.flag = encodeURI(value.flag);
});

export default localeFlags;

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { I18N_TRANSLATIONS_ENDPOINT } from 'students/constants';

type tArgs = {
  locale: string;
  key: string;
  text: string;
};
const updateTranslation = createAsyncThunk<void, tArgs>(
  'translations/update_request',
  async (translation, { rejectWithValue }) => {
    return axios
      .post<tArgs, void>(
        `${I18N_TRANSLATIONS_ENDPOINT}/translations`,
        {
          translation
        },
        { withCredentials: true }
      )
      .then(
        () => {},
        (err) => {
          return rejectWithValue(err?.message || '');
        }
      );
  }
);

export const translationActions = { updateTranslation };

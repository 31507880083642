export enum Preferences {
  MusicPlayback = 'musicPlayback',
  SoundEffects = 'soundEffects',
  HintsEffects = 'trainingHints',
  FontSize = 'fontSize',
  HighContrast = 'highContrast',
  Theme = 'theme',
  I18nEditorMode = 'i18nEditorMode',
  EssayAIPaste = 'essayAIPaste'
}

import {
  dropLessonSession,
  lessonTaskActions,
  profileActions
} from '@adeptlms/lingu-students-react-shared';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { tAppDispatch } from 'students/stores/rootStore';

export const logOutStorageClear = createAsyncThunk<
  void,
  void,
  { dispatch: tAppDispatch }
>('auth/logOutStorageClear_request', (_, { dispatch }) => {
  dispatch(profileActions.removeProfileData());
  dispatch(lessonTaskActions.drop());
  dispatch(dropLessonSession());

  localStorage.clear();
});

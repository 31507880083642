import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import React from 'react';
import { PencilIcon } from 'students/views/shared/components/icons';
import TranslationPopover from './TranslationPopover';
import useTranslationEditor from './useTranslationEditor';

type tPlacement = 'top' | 'bottom' | 'left' | 'right';

export type tPlacementProps = {
  editButtonPlacement?: tPlacement;
  editorPlacement?: tPlacement;
};

interface IProps {
  str: string;
  children: React.ReactNode;
  locale: string;
  translationKey: string;
}

const TranslationEditor: React.FC<IProps & tPlacementProps> = ({
  str,
  children,
  locale,
  translationKey
}) => {
  const { newTranslation, onNewTranslationChange, onSubmit, isPending, responseStatus } =
    useTranslationEditor({ str, locale, translationKey });

  return (
    <div className="ln-relative">
      <div className="ln-relative">{children}</div>

      <Popover className="ln-absolute ln-inset-x-1/3 ln-inset-y-1/2 ln-z-10">
        <PopoverButton className="ln-w-8 ln-h-8 ln-rounded-full ln-bg-yellow/60 hover:ln-bg-yellow  ln-transition-colors ln-group ln-z-10">
          <PencilIcon
            className="group-hover:ln-opacity-100 ln-opacity-45 ln-transition-opacity"
            width="22px"
            height="16px"
          />
        </PopoverButton>

        <PopoverPanel anchor="top" className="ln-z-[100]">
          <TranslationPopover
            newTranslation={newTranslation}
            onNewTranslationChange={onNewTranslationChange}
            onSubmit={onSubmit}
            str={str}
            isPending={isPending}
            responseStatus={responseStatus}
          />
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default TranslationEditor;

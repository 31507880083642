import React, { useContext, FC } from 'react';
import cn from 'classnames';
import I18nContext from '../I18nContext';
import { t } from '../i18nEngine';
import { tProps } from '../types';
import withEditableTranslate from './withEditableTranslate';

const Translate: FC<tProps> = ({ str, params, scope, setDangerously, className }) => {
  const { locale } = useContext(I18nContext);

  if (setDangerously) {
    return (
      <span
        className={cn(className)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t(str, { ...params, scope, locale })
        }}
      />
    );
  }

  return <span className={cn(className)}>{t(str, { ...params, scope, locale })}</span>;
};

export default withEditableTranslate(Translate);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModal } from 'students/models';

const initialModalState: IModal = {
  openedModals: []
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    open: (state, action: PayloadAction<IModal['openedModals'][0]>) => {
      state.openedModals.push(action.payload);
    },
    close: (state, action: PayloadAction<string | string[]>) => {
      if (typeof action.payload === 'string') {
        return {
          openedModals: state.openedModals.filter((modal) => modal.key !== action.payload)
        };
      } else {
        return {
          openedModals: state.openedModals.filter((modal) =>
            action.payload.includes(modal.key)
          )
        };
      }
    },
    closeAll: () => {
      return initialModalState;
    }
  }
});

const {
  reducer,
  actions: { close, closeAll, open }
} = modalSlice;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function openAdapter(modalKey: string, context?: any): ReturnType<typeof open> {
  return open({
    key: modalKey,
    context
  });
}

export const actions = {
  open: openAdapter,
  close,
  closeAll
};

export default reducer;

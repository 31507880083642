import { ErrorBoundary, showReportDialog } from '@sentry/react';
import { Translate, t } from 'i18n';
import { FC, PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';
import { isIE, isIOS } from '../../helpers';
import LinkElem from '../LinkElem';

const ErrorBoundary2: FC<PropsWithChildren> = ({ children }) => {
  const incompatibleBrowser = useMemo(() => isIE(), []);
  const incompatibleIOS = useMemo(() => isIOS(10), []);

  const isIncompatible = incompatibleBrowser || incompatibleIOS;

  return (
    <ErrorBoundary
      showDialog={!isIncompatible}
      dialogOptions={{
        labelSubmit: t('frontend.incompatible_page.report_feedback')
      }}
      fallback={({ eventId }) => {
        const submitButton = (
          <SFeedbackButton
            onClick={() =>
              showReportDialog({
                eventId: eventId || undefined,
                labelSubmit: t('frontend.incompatible_page.report_feedback')
              })
            }
          >
            <Translate str="frontend.incompatible_page.report_feedback" />
          </SFeedbackButton>
        );
        if (isIncompatible) {
          const header = (() => {
            if (incompatibleBrowser)
              return (
                <Translate str="frontend.incompatible_page.your_browser_is_unsupported" />
              );
            else if (incompatibleIOS)
              return (
                <Translate str="frontend.incompatible_page.your_ios_is_unsupported" />
              );
            else return null;
          })();

          const body = (() => {
            if (incompatibleBrowser) {
              return (
                <>
                  <Translate str="frontend.incompatible_page.to_enjoy_try_using_new_browser_like" />{' '}
                  <SLink target="_blank" to="https://www.google.com/intl/com/chrome/">
                    Google Chrome
                  </SLink>
                  ,{' '}
                  <SLink
                    target="_blank"
                    to="https://www.mozilla.org/en-US/firefox/download/thanks/"
                  >
                    Mozilla Firefox
                  </SLink>{' '}
                  <Translate str="frontend.incompatible_page.or" />{' '}
                  <SLink target="_blank" to="https://www.microsoft.com/en-us/edge">
                    Microsoft Edge
                  </SLink>
                </>
              );
            } else if (incompatibleIOS) {
              return (
                <>
                  <Translate str="frontend.incompatible_page.to_enjoy_try_update_ios" />{' '}
                  <SLink target="_blank" to="https://support.apple.com/downloads/ios">
                    10 <Translate str="frontend.incompatible_page.or_later" />
                  </SLink>
                </>
              );
            } else {
              return null;
            }
          })();

          return (
            <SWrapper>
              <SHeading>
                <Translate str="frontend.incompatible_page.we_are_sorry_but" />
                <br />
                <strong>{header}</strong>
              </SHeading>

              <SSubHeading>{body}</SSubHeading>

              {submitButton}
            </SWrapper>
          );
        }

        return (
          <SWrapper>
            <SHeading>
              <strong>
                <Translate str="frontend.incompatible_page.something_went_wrong" />
              </strong>
            </SHeading>

            {submitButton}
          </SWrapper>
        );
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundary2;

const SWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f3f3f8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
`;

const SHeading = styled.h1`
  color: #2d2d3a;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  padding: 0;
  margin: 0 0 20px;
  text-align: center;

  @media (min-width: 644px) {
    margin: 0 0 30px;
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
`;

const SSubHeading = styled.p`
  color: #2d2d3a;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  padding: 0;
  margin: 0 0 24px;
  text-align: center;

  @media (min-width: 644px) {
    margin: 0 0 50px;
    max-width: 670px;
    font-size: 1.125rem;
  }
`;

const SLink = styled(LinkElem)`
  color: #00a5d7;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.625rem;
  padding: 0;
  margin: 0 0 24px;
  text-align: center;
  transition: color 0.3s;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: #00688e;
  }

  &:active {
    color: #00466f;
  }
`;

const SFeedbackButton = styled.button`
  background-color: #00a5d7;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin: 0;
  padding: 0.75rem 1.5rem;
  min-height: 3.125rem;
  max-height: 3.125rem;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  width: 100%;
  transition: background-color 0.3s;

  &:hover,
  &:focus {
    background-color: #00688e;
  }

  &:active {
    background-color: #00466f;
  }

  @media (min-width: 644px) {
    min-height: 3.375rem;
    max-height: 3.375rem;
    font-size: 1.125rem;
    width: auto;
  }
`;

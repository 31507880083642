const TRANSLATION_VAR_REGEXP = new RegExp(/%\{[a-zA-Z_0-9]+\}/g);
const HTML_TAG_REGEXP = new RegExp(
  /<\/?[a-zA-Z]+(\s+[a-zA-Z]+\s*=\s*("([^"]*)"|'([^']*)'))*\s*>/g
);

export const checkIfStringsHaveSameSpecialParts = (
  initialString: string,
  verifiableString: string
): boolean => {
  const isVarPresented = initialString.match(TRANSLATION_VAR_REGEXP);
  if (isVarPresented) {
    const isVarPresentedInVerifiable = verifiableString.match(TRANSLATION_VAR_REGEXP);

    return (
      !!isVarPresentedInVerifiable &&
      isVarPresented.every((matched) => isVarPresentedInVerifiable?.includes(matched))
    );
  }

  const isHtmlTagPresented = initialString.match(HTML_TAG_REGEXP);
  if (isHtmlTagPresented) {
    const isHtmlTagPresentedInVerifiable = verifiableString.match(HTML_TAG_REGEXP);
    return (
      !!isHtmlTagPresentedInVerifiable &&
      isHtmlTagPresented.every(
        (matched, index) => isHtmlTagPresentedInVerifiable?.[index] === matched
      )
    );
  }

  return true;
};

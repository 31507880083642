import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useAnimateScenarios, useBreakPoint } from 'students/views/shared/hooks';
import { animationScenarios } from './animation/scenarios';
import LottieIcon from '../LottieIcon';
import spinnerBubble from './assets/spinnerBubble.json';

const ICON_WIDTH_DESKTOP = '8rem';
const ICON_WIDTH_MOBILE = '4rem';

interface IProps {
  className?: string;
  timeout?: number;
}

const SpinnerBubble: React.FC<IProps> = ({ className }) => {
  const [controls, animateControls] = useAnimateScenarios(animationScenarios);
  const isMobile = useBreakPoint('sm', true);

  useLayoutEffect(() => {
    animateControls(['appear']);
  }, [animateControls]);

  const lottieProps = {
    animationJSONData: spinnerBubble,
    loop: true,
    speed: 1
  };

  return (
    <AnimatePresence>
      <SWrapper
        as={motion.div}
        initial={{
          opacity: 0
        }}
        animate={controls}
        exit={{ opacity: 0, transition: { type: 'Inertia', duration: 0.25 } }}
        className={cn(className)}
      >
        {lottieProps && (
          <LottieIcon
            width={isMobile ? ICON_WIDTH_MOBILE : ICON_WIDTH_DESKTOP}
            autoPlay
            {...lottieProps}
          />
        )}
      </SWrapper>
    </AnimatePresence>
  );
};

export default SpinnerBubble;

const SWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTypedSelector } from 'students/stores/_utils';
import { userSelectors } from 'students/stores/user';
import { DEFAULT_EDITOR_MODE } from 'students/views/shared/providers/Preferences/Config';
import {
  getPreference,
  setPreference
} from 'students/views/shared/providers/Preferences/helpers';
import { Preferences } from 'students/views/shared/providers/Preferences/models';

export const useI18nEditorMode = (): [boolean, () => void] => {
  const [mode, setMode] = useState<boolean>(() =>
    JSON.parse(getPreference(Preferences.I18nEditorMode, DEFAULT_EDITOR_MODE))
  );
  const profile = useTypedSelector(userSelectors.selectProfile);

  const isAllowedToEdit = useMemo(
    () => (profile?.confirmedTranslator ? mode : false),
    [mode, profile?.confirmedTranslator]
  );

  useEffect(() => {
    setPreference(Preferences.I18nEditorMode, isAllowedToEdit);
  }, [isAllowedToEdit]);

  const toggleMode = useCallback(() => {
    setMode((prevMode) => !prevMode);
  }, []);

  return [isAllowedToEdit, toggleMode];
};

import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  to: string;
  children?: ReactNode;
  [key: string]: any;
}

const LinkElem: FC<IProps> = ({ to, children, ...props }) => {
  if (/^https?:\/\//.test(to) || /^mailto:/.test(to)) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  } else {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }
};

export default LinkElem;

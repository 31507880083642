import {
  ENV_AUTH_URL,
  ENV_I18N_TRANSLATIONS_ENDPOINT,
  ENV_IDLE_TIMER,
  ENV_ORDNETT_SEARCH_ENDPOINT,
  ENV_PING_TIMER,
  ENV_SERVER_URL
} from './envs';

import { isProd, isTest } from './modes';

export { isProd, isTest };
export const LINGU_COPYRIGHT = `${new Date().getFullYear()} Lingu AS`;
// preval`module.exports = new Date().getFullYear() + ' Lingu AS';` || '';
export const DEFAULT_IDLE_TIMER = parseInt(ENV_IDLE_TIMER as string, 10) || 180000; // 3min
export const DEFAULT_PING_TIMER = parseInt(ENV_PING_TIMER as string, 10) || 5000; // 5sec

// CSS classes implemented by WebView in the Lingu mobile app
export const PADDINGABLE_FOR_MOBILE_STATUS_BAR_CLASS =
  'paddingable-for-mobile-status-bar ln-pt-safe ln-pr-safe';

// Runtime constants
export const APP_SERVER_URL =
  getRuntimeConstantByName('APP_SERVER_URL') || ENV_SERVER_URL || 'https://lingu.com';
// export const API_URI = isProd ? `${APP_SERVER_URL}/api` : '/api';
export const API_URI = `${APP_SERVER_URL}/api`;

export const AUTH_URL = ENV_AUTH_URL;
export const I18N_TRANSLATIONS_ENDPOINT =
  getRuntimeConstantByName('I18N_TRANSLATIONS_ENDPOINT') ||
  ENV_I18N_TRANSLATIONS_ENDPOINT ||
  'https://i18n.lingu.com/api';

export const ORDNETT_SEARCH_ENDPOINT =
  getRuntimeConstantByName('ORDNETT_SEARCH_ENDPOINT') ||
  ENV_ORDNETT_SEARCH_ENDPOINT ||
  (isProd ? '' : 'https://lingu.com/api/ordnett-proxy');

function getRuntimeConstantByName(name: string): string | undefined {
  const element = document.querySelector(`meta[name="${name}"]`);

  if (element instanceof HTMLMetaElement) {
    return element.content;
  }
  return undefined;
}

export const APP_DOM_ID = 'lingu-app';

export const CONTACT_US_URL = 'https://lingu.no/contact-us';

export function courseRouteLinkFromSlug(slug: string): string {
  return `/courses/${slug}`;
}

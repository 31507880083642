export const ENV_IDLE_TIMER = import.meta.env.VITE_ENV_IDLE_TIMER;
export const ENV_PING_TIMER = import.meta.env.VITE_ENV_PING_TIMER;
export const ENV_AUTH_URL = import.meta.env.VITE_AUTH_URL;
export const ENV_SERVER_URL = import.meta.env.VITE_SERVER_URL;
export const ENV_I18N_TRANSLATIONS_ENDPOINT = import.meta.env
  .VITE_I18N_TRANSLATIONS_ENDPOINT;
export const ENV_ORDNETT_SEARCH_ENDPOINT = import.meta.env.VITE_ORDNETT_SEARCH_ENDPOINT;
export const ENV_MOBILE_PROTOCOL = import.meta.env.VITE_MOBILE_PROTOCOL;
export const ENV_MOBILE_SCHEME = `${ENV_MOBILE_PROTOCOL}://`;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

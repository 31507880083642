import { createSelector, Selector } from '@reduxjs/toolkit';
import type { tAppState } from '../rootStore';

const selectAuthStore: Selector<tAppState, tAppState['auth']> = (state) => state.auth;
const selectModal: Selector<tAppState, tAppState['auth']['modal']> = createSelector(
  [selectAuthStore],
  (authStore) => authStore.modal
);

const selectTempUser: Selector<tAppState, tAppState['auth']['tempUser']> = createSelector(
  [selectAuthStore],
  (authStore) => authStore.tempUser
);

const selectInvitation: Selector<tAppState, tAppState['auth']['invitation']> =
  createSelector([selectAuthStore], (authStore) => authStore.invitation);

const selectInvitationExpired: Selector<
  tAppState,
  tAppState['auth']['invitationExpired']
> = createSelector([selectAuthStore], (authStore) => authStore.invitationExpired);

const authSelectors = {
  selectAuthStore,
  selectModal,
  selectTempUser,
  selectInvitation,
  selectInvitationExpired
};

export default authSelectors;

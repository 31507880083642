import * as Sentry from '@sentry/react';
import { isProd } from 'students/constants';
import initialScope from './sentry/scope';
import sentryBaseConfig from './sentry/sentryBaseConfig';

function initSentry(): void {
  Sentry.init({
    ...sentryBaseConfig,
    allowUrls: ['lingu.com', 'staging.lingu.com', 'capacitor://localhost'],
    beforeSend(event) {
      if (!isProd) return null;
      return event;
    },
    initialScope
  });
  Sentry.setTag('app_platform', initialScope.extra.platform);
}

export default initSentry;

import I18n from 'i18n-js';

const t = I18n.t.bind(I18n);
const l = I18n.localize.bind(I18n);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const lookup = I18n.lookup.bind(I18n);
const { translations } = I18n;

export { I18n, t, l, lookup, translations };

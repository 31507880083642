import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  tSharedAppDispatch,
  profileActions as sharedProfileActions
} from '@adeptlms/lingu-students-react-shared';
import { settingsApi } from 'students/api';

const uploadProfilePhoto = createAsyncThunk<
  void,
  File,
  {
    dispatch: tSharedAppDispatch;
  }
>('profile/uploadPhoto_request', async (photo, { dispatch }) => {
  const { data } = await settingsApi.uploadFile(photo);

  dispatch(sharedProfileActions.updateProfile({ avatar: data }));
});

export const profileActions = { ...sharedProfileActions, uploadProfilePhoto };

import supportedLocaleFlags from './localeFlags';

export const supportedLocales = [...supportedLocaleFlags]
  .map(([locale, localeInfo]) => (localeInfo.flag ? locale : false))
  .filter(Boolean);

export const supportedLocalesWithFlags = [...supportedLocaleFlags];

export const DEFAULT_LOCALE = (() => {
  const lang =
    getLanguageCodeOnly(navigator.language) || document.documentElement.lang || 'en';

  return lang === 'no' ? 'nb' : lang;
})();

function getLanguageCodeOnly(locale: string) {
  return locale?.split('-')[0] || '';
}

export const RTL_LOCALES = [
  'ar',
  'ku',
  'ps',
  'fa',
  'pa',
  'ur',
  'ug',
  'uz',
  'he',
  'yi',
  'sd',
  'ha'
];

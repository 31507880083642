import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import useBreakpointNumber from './useBreakpointNumber';

type tDefaultBreakPointNames = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type tDeviceTypes = 'mobile' | 'tablet' | 'desktop';
type tDeviceBreakPoints = {
  [someKey in tDeviceTypes]: number;
};

export default function useBreakPoint(
  breakPoint: tDefaultBreakPointNames | tDeviceTypes,
  isMaxWidth = false
): boolean {
  const theme = useContext(ThemeContext);
  const { linguBrPts } = theme;
  const deviceBreakpoints: tDeviceBreakPoints = theme.mediaQueryBreakpoints;
  const breakpointNumber =
    deviceBreakpoints[breakPoint as tDeviceTypes] ||
    linguBrPts[breakPoint as tDefaultBreakPointNames];
  return useBreakpointNumber(breakpointNumber, isMaxWidth);
}

export function useMobileBreakPoint(): boolean {
  return useBreakPoint('md', true);
}

export function useDesktopBreakPoint(): boolean {
  return useBreakPoint('lg');
}

import { createSelector, Selector } from '@reduxjs/toolkit';
import type { tAppState } from '../rootStore';

const selectRouterHistoryStore: Selector<tAppState, tAppState['routerHistory']> = (
  state
) => state.routerHistory;

const selectRouterHistoryLocations: Selector<
  tAppState,
  tAppState['routerHistory']['locations']
> = createSelector(
  [selectRouterHistoryStore],
  (routerHistoryStore) => routerHistoryStore.locations
);

const routerHistorySelectors = {
  selectRouterHistoryStore,
  selectRouterHistoryLocations
};

export default routerHistorySelectors;

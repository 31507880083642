import { useIsPending } from '@adeptlms/lingu-students-react-shared';
import { getLocaleTranslation, updateI18nTranslation } from 'i18n/helpers';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTypedDispatch } from 'students/stores/_utils';
import { translationActions } from 'students/stores/translations';
import { checkIfStringsHaveSameSpecialParts } from './helpers';

interface IUseTranslationEditor {
  str: string;
  locale: string;
  translationKey: string;
}

export type tResponseStatus = 'success' | 'failed' | 'pristine';

interface IReturnedObject {
  newTranslation: string;
  onNewTranslationChange: (event: FormEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  isPending: boolean;
  responseStatus: tResponseStatus;
}

export default function useTranslationEditor({
  str,
  locale,
  translationKey
}: IUseTranslationEditor): IReturnedObject {
  const [newTranslation, setNewTranslation] = useState(str);
  const translatableElementRef = useRef<HTMLSpanElement | null>(null);
  const containerRef = useRef<HTMLElement | null>(null);
  const isPending = useIsPending([translationActions.updateTranslation.typePrefix]);
  const timeoutRef = useRef<number | null>(null);
  const [responseStatus, setResponseStatus] = useState<tResponseStatus>('pristine');

  const dispatch = useTypedDispatch();

  useEffect(() => {
    // find parent that is two levels above in the DOM
    const parent = translatableElementRef.current?.parentElement?.parentElement;

    if (parent) {
      // do this check to avoid semantic errors
      // button cannot be ancestor of button element
      if (parent.tagName === 'BUTTON') {
        containerRef.current = parent.parentElement;
      } else {
        containerRef.current = parent;
      }
    }
  }, []);

  useEffect(() => {
    if (responseStatus === 'failed') {
      timeoutRef.current = window.setTimeout(() => {
        setResponseStatus('pristine');
      }, 1000);
    }

    if (responseStatus === 'success') {
      timeoutRef.current = window.setTimeout(() => {
        setResponseStatus('pristine');
      }, 500);
    }
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [responseStatus]);

  const onNewTranslationChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement;

      if (checkIfStringsHaveSameSpecialParts(str, value)) {
        setNewTranslation(value);
      }
    },
    [str]
  );

  const performTranslateSubmit = useCallback(
    async (value: string) => {
      const response = await dispatch(
        translationActions.updateTranslation({
          locale,
          key: translationKey,
          text: value
        })
      );

      if (!translationActions.updateTranslation.rejected.match(response)) {
        setResponseStatus('success');

        /* Updating translations with new data */
        const translationData = await getLocaleTranslation(locale);

        updateI18nTranslation(locale, translationData);
      } else {
        setResponseStatus('failed');
      }
    },
    [dispatch, locale, translationKey]
  );

  const onSubmit = useCallback(() => {
    if (
      newTranslation.trim() !== str.trim() &&
      checkIfStringsHaveSameSpecialParts(str, newTranslation)
    ) {
      performTranslateSubmit(newTranslation);
    }
  }, [newTranslation, str, performTranslateSubmit]);

  return {
    newTranslation,
    onNewTranslationChange,
    onSubmit,
    isPending,
    responseStatus
  };
}

import { userLocaleActions } from '@adeptlms/lingu-students-react-shared';
import I18n from 'i18n-js';
import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTypedDispatch, useTypedSelector } from 'students/stores/_utils';
import userSelectors from 'students/stores/user/selectors';
import I18nContext from '../I18nContext';
import { DEFAULT_LOCALE, RTL_LOCALES } from '../config';
import { changeLocale } from '../helpers';
import TranslationEditToggle from './TranslationEditToggle';
import { useI18nEditorMode } from './useI18nEditorMode';
import { useToggleTranslationEditor } from './useToggleTranslationEditor';

import { en as translationEn } from '../translations/en.json';
import { nb as translationNb } from '../translations/nb.json';

I18n.translations.en = translationEn;
I18n.translations.nb = translationNb;

I18n.defaultLocale = DEFAULT_LOCALE;

const I18nProvider: FC<
  PropsWithChildren<{
    overrideLocaleWith?: string;
  }>
> = ({ children, overrideLocaleWith }) => {
  const locale = useTypedSelector(userSelectors.selectUserLocale);
  const localeToUse = overrideLocaleWith || locale;

  const dispatch = useTypedDispatch();

  const [editorMode, _toggleEditorMode] = useI18nEditorMode();
  const [showTranslationEditor, toggleShowEditor] =
    useToggleTranslationEditor(editorMode);

  useEffect(() => {
    changeLocale(localeToUse);
  }, [localeToUse]);

  const _setLocale = useCallback(
    (_locale: string): void => {
      dispatch(userLocaleActions.updateLocale(overrideLocaleWith || _locale));
      document.documentElement.setAttribute('lang', overrideLocaleWith || _locale);
    },
    [dispatch, overrideLocaleWith]
  );

  useEffect(() => {
    if (RTL_LOCALES.includes(locale)) {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }
  }, [locale]);

  return (
    <I18nContext.Provider
      value={{
        locale: localeToUse,
        editorMode,
        showTranslationEditor,
        setLocale: _setLocale,
        toggleEditorMode: _toggleEditorMode
      }}
    >
      {children}
      {editorMode
        ? ReactDOM.createPortal(
            <TranslationEditToggle
              toggle={toggleShowEditor}
              showEditor={showTranslationEditor}
            />,
            document.querySelector('body') as HTMLElement
          )
        : null}
    </I18nContext.Provider>
  );
};

export default I18nProvider;

import React from 'react';
import {
  DEFAULT_EDITOR_MODE,
  DEFAULT_SHOW_TRANSLATION_EDITOR
} from 'students/views/shared/providers/Preferences/Config';
import { DEFAULT_LOCALE } from './config';

const I18nContext = React.createContext({
  locale: DEFAULT_LOCALE,
  editorMode: DEFAULT_EDITOR_MODE,
  showTranslationEditor: DEFAULT_SHOW_TRANSLATION_EDITOR,
  // eslint-disable-next-line no-console
  setLocale: (_locale: string) => console.log('setLocale handler should be defined'),
  // eslint-disable-next-line no-console
  toggleEditorMode: () => console.log('toggleEditorMode handler should be defined')
});

export default I18nContext;

import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import Lottie, { LottieOptions, LottieRef } from 'lottie-react';
import cn from 'classnames';

interface ILottieIcon {
  animationJSONData: LottieOptions['animationData'];
  loop?: LottieOptions['loop'];
  autoPlay?: LottieOptions['autoPlay'];
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  title?: string;
  className?: string;
  speed?: number;
  destroyOnComplete?: boolean;
  onComplete?: () => void;
}

const LottieIcon: FC<ILottieIcon> = ({
  animationJSONData,
  loop,
  autoPlay,
  className,
  width,
  height,
  title,
  speed,
  destroyOnComplete = false,
  onComplete
}) => {
  const lottieRef: LottieRef = useRef(null);
  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    if (speed) lottieRef.current?.setSpeed(speed);
  }, [speed]);

  const style: CSSProperties = {};
  if (width) style.width = width;
  if (height) style.height = height;

  const handleComplete = useCallback(() => {
    onComplete && onComplete();
    setCompleted(true);
  }, [onComplete]);

  return destroyOnComplete && completed ? null : (
    <Lottie
      lottieRef={lottieRef}
      rendererSettings={{
        className: cn(className),
        preserveAspectRatio: 'xMidYMid slice'
      }}
      animationData={animationJSONData}
      loop={loop}
      autoplay={autoPlay}
      style={style}
      title={title}
      onComplete={handleComplete}
    />
  );
};

export default LottieIcon;
